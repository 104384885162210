import React from 'react';

export default function Footer (){
    return (<footer>
        <div className="container">
          <div className="row">
            <div className="col-12">
              {/* <h5>Enova Creations</h5> */}
              <h3>Enova Creations</h3>
              <ul className="contact-nav">
                <li><a data-scroll href="#home">Home</a></li>
                <li><a data-scroll href="#services">Services</a></li>
                <li><a data-scroll href="#portfolio">Portfolio</a></li>
                {/* <li><a data-scroll href="#testimonials">Testimonials.</a></li> */}
                <li><a data-scroll href="#contact-us">Contact</a></li>
              </ul>
              <h6>© 2021 - Enova Creations,All Right Reserved</h6>
              {/* <ul className="social">
                <li><a href="#"><i className="icofont-facebook"></i></a></li>
                <li><a href="#"><i className="icofont-twitter"></i></a></li>
                <li><a href="#"><i className="icofont-dribbble"></i></a></li>
              </ul> */}
            </div>
          </div>
        </div>
      </footer>)
}
import React from "react"

export default function Header(){
   return ( <header id="home">
   <div className="container">
     <nav className="navbar navbar-expand-lg navbar-light ">
       {/* <!-- Change Logo Img Here --> */}
       <a className="navbar-brand" href="#"><img src="Logo/E_Logo.png" alt=""/></a>
       <button className="navbar-toggler" type="button" >
         <div className="interactive-menu-button">
           <a href="#">
             <span>Menu</span>
           </a>
         </div>
       </button>
       <div className="collapse navbar-collapse" id="navbarSupportedContent">
         <ul className="navbar-nav mr-auto">
           <li className="nav-item active">
             <a className="nav-link" data-scroll href="#home">Home<span className="sr-only">(current)</span></a>
           </li>
           <li className="nav-item">
             <a className="nav-link" data-scroll href="#services">Services</a>
           </li>
           <li className="nav-item">
                           <a className="nav-link" data-scroll href="#portfolio">Portfolio</a>
           </li>
           {/* <li className="nav-item">
  
             <a className="nav-link" data-scroll href="#testimonials">Testimonials</a>
           </li> */}
           <li className="nav-item">
             <a className="nav-link" data-scroll href="#contact-us">Contact</a>
           </li>
           {/* <li className="nav-item dropdown">

             <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">EN</a>
             <div className="dropdown-menu" aria-labelledby="navbarDropdown">

               <a className="dropdown-item" href="#">DE </a>

               <a className="dropdown-item" href="#">ES </a>
             </div>
           </li> */}
         </ul>
         <form data-scroll href="#contact-us" className="contact-btn form-inline my-2 my-lg-0">
           <button>Contact Us</button>
         </form>
       </div>
     </nav>
   </div>
   

 </header>)
}
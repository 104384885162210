import React from "react";

export default function Services(){
    return(  
        <section id="services" className="services">
          <div className="container-fluid">
            <div className="side-img"> 
              <img src="images/aside.svg" alt="" />
            </div>
            <div className="side2-img"> 
              <img src="images/aside2.svg" alt="" />
            </div>
            <div className="container">
              <div className="row">
                <div className="col-12 col-sm-12 col-lg-4 service-txt">
                  <h2 className="mb-5">Anything you need,we’ve got you covered</h2>
                  <div className="hero-btns service-btn">
                    <a data-scroll href="#contact-us">Get in Touch</a>
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-lg-4">
                  <div className="service-box">
                    <img src="images/service-icon1.svg" alt=""/>
                    <h3>Web & Graphic<br/>Design</h3>
                    <p>We create clean modern websites for your brand.</p>
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-lg-4">
                  <div className="service-box">
                    <img src="images/service-icon2.svg" alt=""/>
                    <h3>Web & App<br/>Development</h3>
                    <p>We can create any software application that helps you grow and optimize your business.</p>
                  </div>
                </div>
                <div className="mgl-4 col-12 col-sm-6 col-lg-4">
                  <div className="service-box">
                    <img src="images/service-icon1.svg" alt=""/>
                    <h3>SEO <br/></h3>
                    <p>Rank in the top searches.</p>
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-lg-4">
                  <div className="service-box">
                    <img src="images/service-icon2.svg" alt=""/>
                    <h3>Social Media <br/>Marketing</h3>
                    <p>Get new customers grow your business.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="container">
            <div class="row">
              <div class="col-12 col-sm-12 col-lg-6">
                <img src="images/aboutimg.svg" alt=""/>
              </div>
              <div class="col-12 col-sm-12 col-lg-6">
                <h5></h5>
                <h2>Increase Sales.<br/>Grow your business.</h2>
                <p>We can increase your internet presense. Increase sales and leads. We give you the competitive edge you need to succeed</p>
              </div>
            </div>
          </div>
        </section>)
}

import Header from '../Header';
import Services from "../Services";
import Portfolio from "../Portfolio"
import Contact from '../Contact';
import Footer from '../Footer';
import Testimonials from "../Testimonials";
function PublicApp() {
  return (
    <div>
      <Header/>
      <div className="container-fluid hero">
        <img src="images/hero.svg" alt=""/>
        <div className="container">
          
          <h1>Innovative Creations. <br/> 
          
          </h1>
          
          <p>We make inovative software for all you business needs.<br/>
          Our mission is help your business be competitive in the modern economy.</p>
          <div className="hero-btns">
          
            <a data-scroll href="#services">Our Services</a>
          
            <a data-scroll href="#contact-us">Get in Touch.</a>
          </div>
        </div>
      </div>
      <Services/>
      <Portfolio/>
      <Contact/>
      <Footer/>
    </div>
  );
}

export default PublicApp;
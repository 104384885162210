import PublicApp from './static/PublicApp';
import './App.css';
import React, { useEffect } from 'react';


function App(props) {
  
  return (<PublicApp/>)

}

export default App;


import React from "react";

export default function Portfolio(){
    let portfolio =[{
        title:"MedSina Clinic",
        image:"images/medsinathumbnail.jpg",
        url:"http://medsinaclinic.com/index.html"
        },
        {
            title:"SQ Contracting",
            image:"images/sqcontractingthumbnail.jpg",
            url:"https://www.sqcontracting.nyc/"
            }];
    let portfolio_list = portfolio.map((item,index)=>{

        return( <div key={index} className="col-12 col-lg-4 work-box">
        <div className="photobox photobox_type10">
            <a href={item.url} target="_blank">
            <div className="photobox__previewbox">
              <img src={item.image} className="photobox__preview" alt="Preview" />
              <span className="photobox__label">{item.title}</span>
            </div>
            </a>
          </div>
      </div>)
    });
    
    return(  
        <section id="portfolio" className="portfolio">
    <div className="container-fluid">
      <div className="portfolio-aside">
        <img src="images/aside3.svg" alt=""/>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h2>See some of our<br/>Creative work.</h2>
          </div>
        </div>
        <div className="row">
        {portfolio_list}
        </div>
        <div className="row">
         
        </div>
       { portfolio.length>3 && <div className="row">
          <div className="col-12 more-btn">
            <a className="more-btn-inside">Show More.</a>
          </div>
        </div>}
      </div>
    </div>
  </section>)
}